import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from 'src/app/shared/global';

@Injectable({
  providedIn: 'root'
})
export class RoleMatrixActionService {
//contructor
  constructor(private http: Http,private global: Global) {
    this._global = global;
   }
   //end cunstructor

   //fields
   _global:Global;
  public userDetails: any;
  //end fileds
  //Added by Omkar on 16th Aug 2020
  
  public GetRoleActionMatrix(menuId:any): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "FetchData/GetActionMatrix?UserId="+this.userDetails.userId +"&MenuId="+menuId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
}
